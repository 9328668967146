<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="info__primary">
					<p class="info__email">{{ bank.name }}</p>
				</div>
			</div>
			<div class="contacts__info">
				<div class="info__primary">
					<p class="info__email">{{ bank.code }}</p>
				</div>
			</div>
			<div class="contacts__info">
				<div class="info__primary">
					<p class="info__email">{{ bank.swift }}</p>
				</div>
			</div>
			<p>
				<router-link
					:to="{ name: 'bank-detail', params: { id: bank.id } }"
					class="btn btn__primary"
					id="viewProfile"
				>
					View Detail
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BankItem',
	props: {
		bankData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			bank: this.bankData,
			itemData: '',
			mapStatus: ''
		};
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 60px 300px 1fr 1fr 150px !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.contacts__info {
	overflow: hidden;
}
</style>
