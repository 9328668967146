<template>
	<div>
		<template v-if="loadingStocks">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Stock</h3>
				</div>
				<div class="form__search-wrapper"></div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<div></div>
						<p>Symbol</p>
						<p>Name</p>
						<p>Sector</p>
						<p>Exchange</p>
						<p>Type</p>
						<p>Actions</p>
					</div>
					<div class="contacts__body">
						<template v-if="stocks.length > 0">
							<StockItem v-for="stock in stocks" :key="stock.id" :stockData="stock" />
						</template>
						<template v-else-if="stocks.length === 0">
							<p class="mt-5 pl-5">No Stock found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading Stocks, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import StockItem from './StockItem';

export default {
	name: 'Stock',
	components: {
		Loader,
		Pagination,
		StockItem
	},
	data() {
		return {
			offset: 0,
			limit: 10,
			baseUrl: 'stocks',
			query: '',
			total: 0,
			queryError: '',
			loadingStocks: true
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllStocks([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				this.loadingStocks = false;
				this.query = '';
				this.total = this.totalStocks;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllStocks([this.limit, this.offset]);
		}
	},
	computed: {
		...mapGetters({
			stocks: 'getStocks',
			status: 'getStatus',
			totalStocks: 'getTotalStocks'
		})
	},
	methods: {
		...mapActions(['fetchAllStocks'])
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 2fr 100px 100px 120px 2fr 150px;
}
</style>
