<template>
	<div
		id="bankModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Add Bank</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="addBank">
						<template v-if="getErrorLog.message">
							<div class="invalid-feedback alert alert-danger text-center" role="alert">
								{{ getErrorLog.message }}
							</div>
						</template>
						<div class="form-row">
							<div class="form-group col-md-12">
								<label class="modal__label" for="name">Name</label>
								<input
									:class="[{ 'is-invalid': errors.name }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.name"
									name="name"
									value=""
									type="text"
									class="form-control"
									id="name"
									placeholder="Name"
								/>
								<div class="invalid-feedback">{{ errors.name }}</div>
							</div>
							<div class="form-group col-md-12">
								<label class="modal__label" for="code">Code</label>
								<input
									:class="[{ 'is-invalid': errors.code }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.code"
									name="code"
									value
									type="number"
									class="form-control"
									id="code"
									placeholder="Bank Code"
								/>
								<div class="invalid-feedback">{{ errors.code }}</div>
							</div>
							<div class="form-group col-md-12">
								<label class="modal__label" for="swiftCode">Swift Code</label>
								<input
									:class="[{ 'is-invalid': errors.swift }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.swift"
									name="swiftCode"
									value
									type="text"
									class="form-control"
									id="swiftCode"
									placeholder="Swift Code"
								/>
								<div class="invalid-feedback">{{ errors.swift }}</div>
							</div>
							<div class="form-group col-md-12">
								<label class="modal__label" for="address">Address</label>
								<textarea
									:class="[{ 'is-invalid': errors.Address }]"
									@keydown="clearErrors($event.target.name)"
									v-model="itemData.address"
									name="address"
									value
									type="text"
									class="form-control"
									id="address"
									placeholder="Address"
								/>
								<div class="invalid-feedback">{{ errors.address }}</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal__footer col-md-12 text-center">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						@click.prevent="addBank"
						class="btn btn--md btn__primary"
						type="button"
						v-bind="{ disabled: loading }"
						name="button"
						id="addBank"
					>
						Add Bank
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import bank from '../../utils/validations/banks';

export default {
	name: 'AddBank',
	mixins: [form],
	data() {
		return {
			loading: false,
			itemData: {},
			errors: {}
		};
	},
	computed: {
		...mapGetters(['getStatus', 'getErrorLog'])
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.clearFormField();
			}
		}
	},
	methods: {
		...mapActions(['createBank']),
		addBank() {
			this.validate(this.itemData, bank.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			this.loading = true;
			this.createBank(this.itemData);
			this.loading = false;
			return true;
		}
	}
};
</script>
