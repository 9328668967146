<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="contacts__img">
					<img v-if="stock.logo" :src="stock.logo" alt="Profile picture" />
					<img v-else src="../../assets/img/profile.png" alt="Profile picture" />
				</div>
				<div class="info__primary">
					<p class="info__email">{{ stock.symbol }}</p>
				</div>
			</div>
			<p class="info__email">{{ stock.name }}</p>
			<p class="info__address">{{ stock.sector }}</p>
			<p class="info__address">{{ stock.exchange }}</p>
			<p class="info__address">{{ stock.type }}</p>
			<p>
				<router-link
					:to="{ name: 'stock-detail', params: { id: stock.symbol } }"
					class="btn btn__primary"
					id="stockDetail"
				>
					View Detail
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'StockItem',
	props: {
		stockData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			stock: this.stockData,
			itemData: '',
			mapStatus: ''
		};
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 2fr 100px 100px 120px 2fr 150px !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
